import {motion, AnimatePresence} from "framer-motion";
import {useMediaQuery} from "@mui/material";
import {Direction} from "../FeedBack";
import s from "./Gallery.module.scss";
import {FC} from "react";

const swipeConfidenceThreshold = 10000;

const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
}
type Props = {
    idx: number
    setIdx: (arg: number) => void
    children: JSX.Element
    sliderLength: number
    setDirection: (arg: Direction) => void
}
export const Gallery: FC<Props> = (props) => {
    const {
        children,
        sliderLength,
        setDirection,
        setIdx,
        idx,
    } = props

    const isMobile = useMediaQuery("(max-width:768px)");
    const variants = {
        enter: (idx: number) => {
            return {
                [isMobile ? 'x' : 'y']: idx > 0 ? 1000 : -1000,
                opacity: 0
            };
        },
        center: {
            zIndex: 1,
            [isMobile ? 'x' : 'y']: 0,
            opacity: 1
        },
        exit: (idx: number) => {
            return {
                zIndex: 0,
                [isMobile ? 'x' : 'y']: idx < 0 ? 1000 : -1000,
                opacity: 0
            };
        }
    };
    const paginate = (value: number) => {
        value === -1 ? setDirection('left') : setDirection('right')
        if (idx === sliderLength - 1 && value === 1) {
            setIdx(0);
        } else if (idx === 0 && value === -1) {
            setIdx(sliderLength - 1);
        } else {
            setIdx(idx + value);
        }
    };

    return (
        <>
            <AnimatePresence custom={idx}>
                <motion.div
                    style={{display: 'contents'}}
                    key={idx}
                    custom={idx}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        [isMobile ? 'x' : 'y']: {
                            type: "spring",
                            stiffness: 300,
                            damping: 30
                        },
                        opacity: {duration: 0.2}
                    }}
                    drag={isMobile ? 'x' : 'y'}
                    dragConstraints={{
                        [isMobile ? 'left' : 'top']: 0,
                        [isMobile ? 'right' : 'bottom']: 0
                    }}
                    dragElastic={1}
                    onDragEnd={(e, {offset, velocity}) => {
                        const swipe = swipePower(
                            offset[isMobile ? 'x' : 'y'],
                            velocity[isMobile ? 'x' : 'y']
                        );
                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                >
                    {children}
                </motion.div>
            </AnimatePresence>
            <button className={`${s.btn} ${s.prev}`} onClick={() => paginate(1)}/>
            <button className={s.btn} onClick={() => paginate(-1)}/>
        </>
    );
};

