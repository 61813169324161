import personStyles from './TeamPerson.module.scss'
import {FC} from "react";

type propTypes = {
    person: {
        id: number,
        name: string,
        profession: string,
        photo: string,
        experience: string
    }
}

export const TeamPerson: FC<propTypes> = ({person:{ name, photo, profession, experience }}) => {
    return (
        <div className={personStyles.body}>
            <div className={ personStyles.photoContainer }>
                <img  src={ photo } alt="" className={ personStyles.photo }/>
            </div>
            <h2 className={ personStyles.name }>{ name }</h2>
            <p className={ personStyles.profession } >{ profession }</p>
            <p className={ personStyles.experience } >{ experience }</p>
        </div>
    )
}