import React from "react"
import s from "./JellyFishFooter.module.scss";
import bigJellyfishHead from "../../../assets/graphics/footer/footer-meduz-head.svg";
import bigJellyfishTail from "../../../assets/graphics/footer/footer-meduz-tail.svg";


function JellyFishFooter() {
    return <div className={s.bigJellyfishBody}>
        <img src={bigJellyfishHead} alt="img" className={s.bigJellyfishHead}/>
        <img src={bigJellyfishTail} alt="img" className={s.bigJellyfishTail}/>
    </div>;
}

export default JellyFishFooter
