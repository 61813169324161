import React, {useEffect, useState} from "react";
import technologyContainerStyles from "./TechnologyContainer.module.scss";
import {useMediaPredicate} from "react-media-hook";
import WordCloud from 'react-d3-cloud'

type payloadType = {
    id: number,
    description: string,
    color: string,
    fontSize: number,
}

type propTypes = {
    id: number
    title: string
    payload: Array<payloadType>
    showId: number
    changeShowState: (value: number) => void
}

type WordArray = {
    text: string,
    value: number,
    color: string,
}

let timeoutId: any

export const TechnologyContainer: React.FC<propTypes> = props => {

    const isTab = useMediaPredicate('(max-width: 700px)')
    const is500 = useMediaPredicate('(max-width: 500px)')

    const {
        id,
        title,
        payload,
        showId,
        changeShowState
    } = props

    const [words, setWord] = useState<Array<WordArray>>([])
    const [mainState, setMainState] = useState<boolean>(false)
    const [accordionState, setAccordionState] = useState<boolean>(false)
    const [showContent, setShowContent] = useState<boolean>(false)

    const [dummy, setDummy] = useState(true)

    const changeMainState = () => {
        if (showId === 0) {
            setMainState(!mainState)
            changeShowState(id)
        } else {
            if (showId === id) {
                setMainState(!mainState)
            } else {
                changeShowState(id)
                //setShowContent(false)
                setTimeout(() => {
                    setMainState(!mainState)
                }, 600)
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setDummy(!dummy)
        }, 50)
    },[showContent])

    useEffect(() => {
        if (showId !== id && mainState) {
            setMainState(false)
        }
    }, [showId])

    useEffect(() => {
        if (mainState) {
            setShowContent(true)
            setAccordionState(true)
        } else {
            clearTimeout(timeoutId)
            setAccordionState(false)
            setShowContent(false)
            setTimeout(() => {
                if (showId === id) {
                    changeShowState(0)
                }
            }, 600)
        }
    }, [mainState])

    useEffect(() => {
        const temp = payload.map((i: payloadType) => {
            return (
                {text: i.description, value: i.fontSize, color: i.color}
            )
        })
        setWord(temp)
    }, [])

    useEffect(() => {
        if (showContent) {
            timeoutId = setTimeout(() => {
                setDummy(!dummy)
            }, 15000)
        }
    }, [dummy, showContent])

    return (
        <div className={technologyContainerStyles.tab}>

            <div
                className={`${technologyContainerStyles.title} ${mainState && technologyContainerStyles.title__active}`}
                onClick={changeMainState}
            >
                <div className={technologyContainerStyles.title__text}>{title}</div>
            </div>

            <div
                className={`${technologyContainerStyles.tabContent} ${accordionState && technologyContainerStyles.tabContentActive}`}
                style={{
                    height: !isTab
                        ? words.length <= 3
                            ? accordionState ? '200px' : '0px'
                            : accordionState ? '300px' : '0px'
                        : words.length <= 3
                            ? accordionState ? '100px' : '0px'
                            :  !is500
                                ? accordionState ? '180px' : '0px'
                                : accordionState ? '130px' : '0px'
                }}
            >

                {showContent && <WordCloud
                    data={words}
                    width={!isTab ? 1109 : 800}
                    height={
                        !isTab
                            ?  words.length <= 3
                                ? accordionState ? 200 : 0
                                : accordionState ? 300 : 0
                            : words.length <= 3
                                ? accordionState ? 150 : 0
                                : accordionState ? 200 : 0
                    }
                    rotate={0}
                    spiral='rectangular'
                    padding={5}
                    fontSize={word => word.value}
                    fill={(word: WordArray) => word.color}
                />}
            </div>
        </div>
    )
}


