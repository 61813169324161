import footerStyles from './Footer.module.scss';
import sMain from "../../common/styles/styles.module.scss";
import ln from "../../assets/graphics/li.png";
import up from "../../assets/graphics/up.png";
import designrush from "../../assets/graphics/networks-icons/designrush.png";
import greece from '../../assets/graphics/footer/greece.webp'
import littleJellyfish from "../../assets/graphics/meduz 1.svg";
import {Id, Name, Icon} from "../../data/dataTsConfiguration";
import {Link} from "react-scroll";
import JellyFishFooter from "../C011-Common/JellyFishFooter/JellyFishFooter";

type SocialNetworks = {
  id: Id;
  name: Name;
  icon: Icon;
  link: string;
}

const footerTitle = 'CONTACT US';
const greecePhoneNumber = '+306983041632 ';
const UKPhoneNumber = '+4407863717550';
const phoneNumber = '+380930127846 ';
const email = 'info@meduzzen.com';

const socialNetworks: SocialNetworks[] = [
  {id: 1, name: 'linkedIn', icon: ln, link: 'https://www.linkedin.com/company/meduzzen/'},
  {id: 2, name: 'upWork', icon: up, link: 'https://www.upwork.com/ag/meduzen/'},
  {id: 3, name: 'Meduzzen agency on DesignRush', icon: designrush, link: 'https://www.designrush.com/agency/profile/meduzzen'},
]


export const Footer = () => {

  const socialNetworksArray = socialNetworks.map(network => (
    <div className={footerStyles.networkContainer} key={network.id}>
      <a href={network.link} target='blanc'>
        <img src={network.icon} alt={network.name}/>
      </a>
    </div>
  ))

  return (
    <footer className={footerStyles.mainContainer} id='contact'>
      <img src={littleJellyfish} alt='img' className={footerStyles.littleJellyfish}/>

      <JellyFishFooter/>

      <div className={footerStyles.wrapper}>
        <h2 className={`${sMain.title} ${footerStyles.title}`}>{footerTitle}</h2>

        <div className={footerStyles.contentContainer}>
          <div className={footerStyles.contacts}>
            <div className={footerStyles.phoneNumberContainer}>
              <p className={footerStyles.phoneNumber}> {greecePhoneNumber} </p>
              <div className={`${footerStyles.flag} ${footerStyles.ukraineFlag}`}>
                <div className={footerStyles.imageContainer}/>
              </div>
            </div>

            <div className={footerStyles.phoneNumberContainer}>
              <p className={footerStyles.phoneNumber}> {phoneNumber} </p>
              <div className={`${footerStyles.flag} ${footerStyles.ukraineFlag}`}>
                <p className={footerStyles.blue}/>
                <p className={footerStyles.yellowUkraine}/>
              </div>
            </div>

            <p className={footerStyles.email}> {email} </p>
          </div>
          <div className={footerStyles.makeContactWithUs}>
            <div>
              <a href='https://calendly.com/meduzzen' target='_blank' rel="noreferrer">
                <button className={sMain.contactWithUsBtn}>
                  Request a call
                </button>
              </a>
            </div>

            <Link
                to='request'
                delay={0}
                duration={600}
                isDynamic={true}
                offset={150}
            >
              <button className={sMain.contactWithUsBtn}>
                Request a quote
              </button>
            </Link>

          </div>
        </div>
        <div className={footerStyles.socialNetworks}>
          {socialNetworksArray}
        </div>
      </div>
    </footer>
  )
}