import aboutUsMainStyles from './AboutUsMain.module.scss'
import sMain from "../../../common/styles/styles.module.scss";
import  { AboutUsIntroduce } from "../AboutUsIntroduce";
import { Team } from "../Team";
import meduzOne from '../../../assets/graphics/meduz 1.svg'

const mainTitle = 'ABOUT US'

export const AboutUsMain = () => {

    return (
        <section className={ aboutUsMainStyles.mainSection }>
            <div className={ aboutUsMainStyles.wrapper }>
                <h2 className={`${sMain.title} ${aboutUsMainStyles.titlePosition}`} id='about'>{mainTitle}</h2>
                <img src={ meduzOne } className={ aboutUsMainStyles.meduzOne } alt="img" />
                <AboutUsIntroduce/>
                <img src={ meduzOne } className={ aboutUsMainStyles.meduzOneRight } alt="img" />
                <Team/>
            </div>
        </section>
    )
}