import introdiceStyles from './AboutUsIntroduce.module.scss'
import bigJellyfishHead from "../../../assets/graphics/footer/footer-meduz-head.svg";
// import AlexKalmykov from "../../../assets/graphics/team/AlexKalmykov.webp";
// import KateRozhok from "../../../assets/graphics/team/KateRozhok.webp";
// import ArtemLogachev from "../../../assets/graphics/team/ArtemLogachev.webp";
// import SergeiLemeshev from "../../../assets/graphics/team/SergeiLemeshev.webp";
// import OlhaKalinina from "../../../assets/graphics/team/OlhaKalinina.webp";

const mainTextContent = 'Meduzzen is an IT company which provides full range of design and development services in software, web and mobile application, e-commerce, data analysis and web marketing using the most relevant and progressive technologies in multiple domains such as blockchain, logistics, FinTech, IoT and AI to accomplish any task for a customer from any part of the world. '
const secondaryText = 'The work on projects involves top-level specialists, which guarantees progressive teams capable of delivering results in the shortest possible time and meeting all kinds of client\'s needs.'

export const AboutUsIntroduce = () => {
    return (
        <div>
            <div className={ introdiceStyles.introduceBox }>
                <p className={ introdiceStyles.introduceText }>{ mainTextContent }</p>
                <p className={`${introdiceStyles.introduceText} ${introdiceStyles.secondary}`}>{ secondaryText }</p>
                <img className={ introdiceStyles.bigJellyfishHead } src={ bigJellyfishHead } alt="meduzzen"/>
                {/*<div className={ introdiceStyles.introducePhotoContainer }>*/}
                {/*    <img*/}
                {/*        className={`${introdiceStyles.introducePhoto} ${introdiceStyles.alex}` }*/}
                {/*        src={ AlexKalmykov }*/}
                {/*        alt="Alex Kalmykov"/>*/}
                {/*</div>*/}
                {/*<div className={`${introdiceStyles.introducePhotoContainer} ${introdiceStyles.kate}` }>*/}
                {/*    <img*/}
                {/*        className={ introdiceStyles.introducePhoto }*/}
                {/*        src={ KateRozhok }*/}
                {/*        alt="Kate Rozhok"/>*/}

                {/*</div>*/}
                {/*<div className={`${introdiceStyles.introducePhotoContainer} ${introdiceStyles.artem}` }>*/}
                {/*    <img*/}
                {/*        className={ introdiceStyles.introducePhoto }*/}
                {/*        src={ OlhaKalinina }*/}
                {/*        alt="Olha Kalinina"/>*/}
                {/*</div>*/}
                {/*<div className={`${introdiceStyles.introducePhotoContainer} ${introdiceStyles.sergey}` }>*/}
                {/*    <img*/}
                {/*        className={ introdiceStyles.introducePhoto }*/}
                {/*        src={ SergeiLemeshev }*/}
                {/*        alt="Sergei Lemeshev"/>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}