import React, {useState} from 'react';
import itemS from './ServicesItem.module.scss'
// import {useMediaPredicate} from "react-media-hook";
import {scrollHelper} from "../../utils/scrollHelper";

type propsType = {
    id: number,
    title: string,
    icon: string,
    description: string
}

export const ServicesItem: React.FC<propsType> = props => {

    const { icon, title, description} = props
    // const is1200 = useMediaPredicate('(max-width: 1200px)')

    const [mouseHover, setMouseHover] = useState<boolean>(false)
    const [showContent, setShowContent] = useState<boolean>(false)

    const changeIsOpenDescriptions = (value: boolean, type: "hover" | "click") => {
        if (showContent) {
            setTimeout(() => {
                setShowContent(value)
            }, 400)
        } else {
            setShowContent(value)
        }
        setMouseHover(value)
        // if (type === "hover") {
        //     if (is1200) {
        //         setMouseHover(value)
        //     } else {
        //         setMouseHover(value)
        //     }
        // } else {
        //     if (is1200) {
        //         setMouseHover(value)
        //     } else {
        //         setMouseHover(value)
        //     }
        // }
    }

    return (
        <div className={itemS.serviceItem__body}>
            <div
                className={`${itemS.serviceItem__content} ${showContent && itemS.serviceItem__contentAnimation} ${mouseHover && itemS.serviceItem__contentActive}`}
                onClick={() => changeIsOpenDescriptions(!mouseHover, "click")}
                onMouseEnter={() => changeIsOpenDescriptions(true, "hover")}
                onMouseLeave={() => changeIsOpenDescriptions(false, "hover")}
            >
                <div className={itemS.serviceItem__title}>
                    <img src={icon} className={itemS.serviceItem__logo} alt="service icon"/>
                    <p className={itemS.servicesItem__titleText}>{title}</p>
                </div>

                <div
                    className={`${itemS.serviceItem__description} ${mouseHover && itemS.serviceItem__descriptionActive}`}
                >
                    <div className={itemS.serviceItem__descriptionText}>{description}</div>
                    <button className={itemS.serviceItem__button} onClick={() => scrollHelper('request')}>
                        Order
                    </button>
                </div>

            </div>
        </div>
    )
}