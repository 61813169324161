import { FC, useMemo, useState } from 'react';
import { IMainContext, MainContext } from './MainContext';

const defaultValue = {
    isInfoWindowOpened: false
};

type Props = {
    children: JSX.Element
}
export const MainContextProvider: FC<Props> = ({ children }) => {

    const [mainContext, setMainContext] = useState<IMainContext>(defaultValue);

    const defaultProps = useMemo(() => ({
        mainContext,
        setMainContext
    }), [mainContext]);

    return (
        <MainContext.Provider value={defaultProps}>
            {children}
        </MainContext.Provider>
    );
};
