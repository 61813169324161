import s from './Services.module.scss';
import sMain from './../../common/styles/styles.module.scss';
import meduzOne from '../../assets/graphics/meduz 1.svg';
import {ServicesItem} from "./ServicesItem";
import {servicesList} from "../../data/componentsData";
// import {useMediaPredicate} from "react-media-hook";
import {IServices} from "../../data/dataTsConfiguration";

export type stickyType = { stickyHeader: boolean }

export const Services: React.FC<stickyType> = (props) => {
    // const {stickyHeader} = props
    // const is1200 = useMediaPredicate('(max-width: 1200px')
    const title = 'SERVICES'
    const textOne = 'We offer'
    const textTwo = 'and do it well'

    return (
        <div className={`${s.services}`}>
            <div className={`${s.servicesContainer}`}>

                <h2 className={`${sMain.title} ${s.titlePosition}`} id='services'>{title}</h2>
                <div className={s.textOne}>{textOne}</div>
                <div className={s.textTwo}>{textTwo}</div>

                <div className={s.itemContainer}>
                    {servicesList.map((item: IServices) => (
                        <ServicesItem key={item.id} {...item}/>
                    ))}
                </div>

            </div>
            <div id='request'>{}</div>
            <img className={s.meduzOne} src={meduzOne} alt="img"/>
        </div>
    )
}