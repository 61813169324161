import sMain from "../../../common/styles/styles.module.scss";
import { Carousel } from "../Carousel";
import { aboutUsData } from "../../../data/componentsData";
import {useMediaPredicate} from "react-media-hook";
import {useEffect, useState} from "react";

export const BusinessDepartment = () => {
    const is1160 = useMediaPredicate('(max-width: 1160px)')

    const [ forceUpdate, setForceUpdate ] = useState(false)

    useEffect(() => {
      setForceUpdate(!forceUpdate)
    },[is1160])

    return(
        <div >
            <h3 className={ sMain.departmentTitle }>Business Development Department</h3>
            <Carousel data={ aboutUsData.business } autoplay={ true } delay={ 4000 }/>
        </div>
    )
}