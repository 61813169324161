import executiveDepartmentStyles from './ExecutiveDepartment.module.scss'
import sMain from "../../../common/styles/styles.module.scss";
import { Carousel } from "../Carousel";
import { aboutUsData } from "../../../data/componentsData";

export const ExecutiveDepertment = () => {
    return(
        <div className={ executiveDepartmentStyles.mainContainer }>
            <h3 className={ sMain.departmentTitle }>Executive Department</h3>
            <Carousel data={ aboutUsData.executive } autoplay={true} delay={ 2500 }/>
        </div>
    )
}