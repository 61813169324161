import s from './Header.module.scss';
import {headerButtonsList} from "../../data/componentsData";
import logo from '../../assets/graphics/horizontalLogo.png';
import {Link} from 'react-scroll';
import {IHeaderMainProps} from "../../data/dataTsConfiguration";
import {useMediaPredicate} from "react-media-hook";
import {scrollHelper} from "../../utils/scrollHelper";
import {useState} from "react";
import useBodyScrollLock from "../../hooks/useBodyScrollLock";


export const Header: React.FC<IHeaderMainProps> = (props) => {

    const {stickyHeader} = props

    const isMobile = useMediaPredicate('(max-width: 1200px')
    const toggle = useBodyScrollLock();


    const [showBurger, setShowBurger] = useState<boolean>(false)

    const changeBurgerState = (value: boolean) => {
        setShowBurger(value)
        toggle(value)
    }

    const headerButtonsArray = headerButtonsList.map((item) => (
        // <a href={`#${item.name}`} key={item.id} className={s.itemMenu}>{ item.name.toUpperCase() }</a>
        <Link
            key={item.id}
            className={s.itemMenu}
            to={`${item.name}`}
            offset={stickyHeader ? -100 : isMobile ? -30 : -200}
            delay={0}
            duration={600}
            isDynamic={true}
            onClick={() => changeBurgerState(false)}
        >
            {item.title.toUpperCase()}
        </Link>
    ))

    return (
        <>
            <div className={`${showBurger && s.burgerBackground}`} onClick={() => changeBurgerState(false)}>{}</div>
            <div className={`${s.header} ${!isMobile && stickyHeader ? s.stickyHeader : s.unStickyHeader}`}
                 id='header'>

                <div className={s.headerContainer}>

                    <div className={s.itemLogo} onClick={() => scrollHelper('header', -100000000)}>
                        <img src={logo} alt="logo" className={s.logo}/>
                    </div>

                    <button className={s.burgerBtn} onClick={() => changeBurgerState(!showBurger)}>
                        <span className={s.burgerLine}/>
                    </button>

                    <div className={`${s.navigationContainer} ${showBurger && s.navigationSidebar}`}>
                        {headerButtonsArray}
                    </div>

                </div>
            </div>
        </>
    )
}
