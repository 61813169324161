import {createContext} from 'react';

export interface IMainContext {
    isInfoWindowOpened: boolean
}

interface IMainContextProps {
    mainContext?: IMainContext,
    setMainContext?: (arg: IMainContext) => void,
}

export const MainContext = createContext<IMainContextProps>({});