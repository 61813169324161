//services
import intelligenceIcon from "../assets/graphics/services-icons/intelligence_aritifical_icon.png";
import webDevIcon from "../assets/graphics/services-icons/web_dev_icon.png";
import softwareDevIcon from "../assets/graphics/services-icons/software_development_icon.png";
import mobileAppsIcon from "../assets/graphics/services-icons/mobile_apps_icon.png";
import blockchainIcon from "../assets/graphics/services-icons/blockchain_dev_icon.png";
import internetIcon from "../assets/graphics/services-icons/internet_block.png";
import dashboardIcon from "../assets/graphics/services-icons/dashboars_icon.png";
import dataAnalysisIcon from "../assets/graphics/services-icons/data_analysis_icon.png";
import webSassIcon from "../assets/graphics/services-icons/web_sass_apps_icon.png";
import apiIcon from "../assets/graphics/services-icons/API_integrations_icon.png";

//team
import VladGolovanov from "../assets/graphics/team/VladGolovanov.jpeg"
import BogdanKostenko from "../assets/graphics/team/BogdanKostenko.jpg"
import KirillLeontyev from "../assets/graphics/team/KirillLeontyev .jpg"
import ArtemLogachev from "../assets/graphics/team/ArtemLogachev.jpg"
import SergeiLemeshev from "../assets/graphics/team/SergeiLemeshev.jpg"
import EduardTerletskiy from "../assets/graphics/team/EduardTerletskiy.jpeg"
import RomanGrebinchak from "../assets/graphics/team/RomanGrebinchak.jpg"
import VitaliySekach from "../assets/graphics/team/VitaliySekach.jpg"
import KateRozhok from "../assets/graphics/team/KateRozhok.jpg"
import IrinaIskenderova from "../assets/graphics/team/IrinaIskenderova.jpg"
import AndriiNikolaiev from "../assets/graphics/team/AndriiNikolaiev.jpg"
import DariaKorotkaya from "../assets/graphics/team/DariaKorotkaya.png"
import HennadiyMitrov from "../assets/graphics/team/HennadiyMitrov.png"
import RomanPoplietukhin from "../assets/graphics/team/RomanPoplietukhin.jpg"
import PavelKononenko from "../assets/graphics/team/PavelKononenko.jpg"
import DianaDavlatova from "../assets/graphics/team/DianaDavlatova.png"
import ValentinVolynskiy from "../assets/graphics/team/ValentinVolynskiy.jpg"
import YaroslavKovaliv from "../assets/graphics/team/YaroslavKovaliv.jpg"
import IvanShulhan from "../assets/graphics/team/IvanShulhan.jpg"
import BogdanPizhuk from "../assets/graphics/team/BogdanPizhuk.jpg"
import MarynaTomchyshena from "../assets/graphics/team/MarynaTomchyshena.jpg"
import AndreyDmitriyev from "../assets/graphics/team/AndreyDmitriyev.jpg"
import MichaelSizonenko from "../assets/graphics/team/MichaelSizonenko.jpg"
import AndreyMillovich from "../assets/graphics/team/AndreyMillovich.png"
import VladSvyrydenko from "../assets/graphics/team/VladSvyrydenko.jpg"
import OleksiiSkursky from "../assets/graphics/team/OleksiiSkursky.jpg"
import MarkoKhodan from "../assets/graphics/team/MarkoKhodan.png"
import SergeiHoncharov from "../assets/graphics/team/SerhiiHoncharov.jpg"
import MarkZaianchkovskyi from "../assets/graphics/team/MarkZaianchkovskyi.jpg"
import MichaelPotemkin from "../assets/graphics/team/MichaelPotemkin.png"
import OxanaVorobel from "../assets/graphics/team/OxanaVorobel.jpg"
import VladymyrIvanus from "../assets/graphics/team/VolodymyrIvanus.jpeg"
import ArturNesterenko from "../assets/graphics/team/ArturNesterenko.png"
import ArtemHirzhev from "../assets/graphics/team/ArtemHirzhev.png"
import YaroslavBorysko from "../assets/graphics/team/YaroslavBorysko.jpg"
import TanyaHrytsiuk from "../assets/graphics/team/TanyaHrytsiuk.jpg"
import DmitryDzyubenko from "../assets/graphics/team/DmitryDzyubenko.jpg"
import SoldatovSerhii from "../assets/graphics/team/SoldatovSerhii.jpg"
import PavelMaslo from "../assets/graphics/team/PavelMaslo.jpg"
import VladyslavRybnyk from "../assets/graphics/team/VladyslavRybnyk.jpeg"
import EugeneKudrin from "../assets/graphics/team/EugeneKudrin.png"
import DmytroVikhtiuk from "../assets/graphics/team/DmytroVikhtiuk.jpg"
import ValentynSlivko from "../assets/graphics/team/ValentynSlivko.jpg"
import IgorSavula from "../assets/graphics/team/IgorSavula.jpg"
//ts
import {
    IHeader,
    ITitleId,
    ITechnologies,
    IServices,
    IInputs,
    IPortfolio,
    IAboutUs,
    IFeedbackItem
} from "./dataTsConfiguration";

type Feedback = {
    [key: string]: IFeedbackItem[]
}

export const testimonialsItemRate = [
    {
        id: 1,
        skills: '5.0',
        title: "Skills",
    },
    {
        id: 2,
        skills: '5.0',
        title: "Availability",
    },
    {
        id: 3,
        skills: '5.0',
        title: "Communication",
    },
    {
        id: 4,
        skills: '5.0',
        title: "Quality",
    },
    {
        id: 4,
        skills: '5.0',
        title: "Deadlines",
    },
    {
        id: 4,
        skills: '5.0',
        title: "Cooperation",
    },
];

export const feedbackItems: Feedback = {
    0: [
        {
            title: 'Install Open Source Tool, Test Functionality, and Report Back',
            message: 'Highly skilled. Answered our question immediately. Great technical skills. Thank you very much. Would hire again.',
            id: '43',
            date: 'Jul 21, 2023',
            author: 'Melanie W',
            country: 'Belgium'
        },
        {
            title: 'Website fixes, Design',
            message: "He is always very good. He goes beyond what he should do to make me satisfied. He is very talented and skilled. I would allways recommend him.",
            id: '1',
            date: 'Jul 11, 2023',
            author: 'Mick M.',
            country: 'Switzerland'
        },
        {
            title: 'Front-End Developer for Google Maps API integration',
            message: "Roman completed a Google Maps API project for us and helped with other front-end development work that required Jekyll and Django knowledge. He is responsive and easy to work and communicate with. I am sure we will work again in the future.",
            id: '2',
            date: 'Jul 11, 2023',
            author: 'Nikola S.',
            country: 'Canada'
        },
        {
            title: 'Need experienced webapp developer for AI application',
            message: 'Vlad is very knowledgeable and prompt. He\'s also a very good communicator. He will be an asset to your team.',
            id: '44',
            date: 'Jul 19, 2023',
            author: 'Apratim B',
            country: 'United States'
        },
        // {
        //     title: 'Front-End Developer for Google Maps API integration',
        //     message: "Solved the task perfectly and was very responsive. And helpful throughout the whole process.",
        //     id: '3',
        //     date: 'Jul 11, 2023',
        //     author: 'Erlend A.',
        //     country: 'Norway'
        // },
        {
            title: 'Need Fullstack Developer to Install Programs on Server',
            message: "Serhii is an outstanding programmer. Very fast and efficient with his work and has excellent communication skills. He is always fast to answer my messages and never left me hanging. He is very knowledgeable and was able to solve my problem where others have failed. Wouldn't hesitate to recommend him to anyone.",
            id: '4',
            date: 'Jul 10, 2023',
            author: 'Clint F.',
            country: 'United States'
        },
        {
            title: 'Django Expert Needed To Rewrite Code',
            message: "Excellent programmer, I have worked with Serhii twice now and both times have been a pleasure. He is an excellent communicator as well as programmer, always quick to solve the issue as he is very knowledgable. Would recommend to anyone!",
            id: '5',
            date: 'Jul 7, 2023',
            author: 'Clint F.',
            country: 'United States'
        },
        {
            title: 'Website fixes, Design',
            message: "He is very fast and as already mentioned he works until the customer is satisfied, which is the most important point for me. He understands what you want from him and implements it directly. I am very satisfied with him. In any case, he has now gained a regular customer.",
            id: '6',
            date: 'Jul 6, 2023',
            author: 'Mick M.',
            country: 'Switzerland'
        },
        {
            title: 'Website fixes,Terms of Use, Policy, Design',
            message: "He is really talented. He listened to all my needs and did more than was necessary until I was satisfied. I would hire him for my next job as well",
            id: '7',
            date: 'Jul 3, 2023',
            author: 'Mick M.',
            country: 'Switzerland'
        },
        {
            title: 'Convert our Node backend and React frontend application from javascript to typescript',
            message: "Andrew is one of the greatest back end I ve met. I am really I got to work with him! Everything went well and flowful. I will use his service again",
            id: '8',
            date: 'Jun 21, 2023',
            author: 'Samuel N.',
            country: 'France'
        },
        {
            title: 'Change the Contract and change the email',
            message: "As with the previous work, I am very enthusiastic. Worked quickly and well again and is always friendly and helpful. Can only be recommended.",
            id: '9',
            date: 'Jun 21, 2023',
            author: 'Mick M.',
            country: 'Switzerland'
        },
        {
            title: 'KYC integration',
            message: 'I can\'t say it enough how much he helps us. He went beyond our expectation. He made suggestions and impressed us with his commitment. I can wholeheartedly recommend this developer. Competent, fast, friendly and there\'s really nothing more to say. I\'m simply impressed and will always go back to him.',
            id: '12',
            date: 'Jun 12, 2023',
            author: 'Mick M.',
            country: 'Switzerland'
        },
        {
            title: 'Fix bug in flask/heroku app - RuntimeError: No application found.',
            message: 'A+',
            id: '13',
            date: 'Jun 7, 2023',
            author: 'Majeed S.',
            country: 'United Kingdom'
        },
    ],
    1: [



        {
            title: 'Heroku expert',
            message: 'Great experience with Valentin. Helped us solve a tricky problem.',
            id: '14',
            date: 'Jun 7, 2023',
            author: 'Majeed S.',
            country: 'United Kingdom'
        },
        {
            title: 'Existing ReactJS app Magento 2 API edit',
            message: 'It was a pleasure working with Roman from start to finish. Completed the work perfectly and was very understanding and patient with changes we requested.',
            id: '15',
            date: 'Jun 7, 2023',
            author: 'James W.',
            country: 'United Kingdom'
        },
        {
            title: 'Cypress tests automated in github actions',
            message: 'Its a pleasure to work with Andrew, he is a skilled engineer and he always deliver up to the expectations.',
            id: '16',
            date: 'May 19, 2023',
            author: 'Roch D.',
            country: 'France'
        },
        {
            title: 'Python developer need to connect Google Sheets and Airtable',
            message: 'Artem did a fantastic job on this request. He understood all the requirements and quickly came with back the results and requested updates. I\'ll definitely work with him again!',
            id: '17',
            date: 'May 18, 2023',
            author: 'Charles W.',
            country: 'United States'
        },
        {
            title: 'Python Developer - Work in Existing Codebase to Add support for calling a social API',
            message: 'Extremely thoughtful and skilled developer who is hard-working and diligent. He is capable of much bigger projects than this, including large project with end-to-end development. He can work solo well and does not require so much handholding on the client\'s part, so I think he is worthwhile to lead complex projects. If I could give beyond 5 stars I would. I only had to do a little bit of testing and is reliable to fix any bugs that arise because he is a trustworthy developer. Do not look any further then Dmytro even for the most ambitious of projects, he can handle it well!',
            id: '18',
            date: 'May 16, 2023',
            author: 'Evan F.',
            country: 'United States'
        },
        {
            title: 'Upgrade and extension of an existing Django web application (Kronos)',
            message: 'Perfect, as always. Thanks, Hennadii!',
            id: '19',
            date: 'May 11, 2023',
            author: 'Christian H.',
            country: 'Luxembourg'
        },
        {
            title: 'Python developer to help with linking a form to image api',
            message: 'Great communication, discovery work and code. Thank you so much for the hard work and brainstorming!',
            id: '20',
            date: 'Apr 6, 2023',
            author: 'Andrew R.',
            country: 'United States'
        },
        {
            title: 'Fix Python script',
            message: 'Great work! Recommend!',
            id: '21',
            date: 'Mar 13, 2023',
            author: 'Oleksandr B.',
            country: 'Ukraine '
        },
        {
            title: 'Upgrade and extension of an existing Django web application (KCA)',
            message: 'Thanks a lot to Hennadii and his team. Until next time :-)!',
            id: '22',
            date: 'May 11, 2023',
            author: 'Christian H.',
            country: 'Luxembourg'
        },
        {
            title: 'Convert Python code to javascript',
            message: 'Artem was very responsive and went above and beyond to complete the project.',
            id: '23',
            date: 'Feb 20, 2023',
            author: 'David P.',
            country: 'United States'
        },
        {
            title: 'Auth0 implementation in Python APIs and authentication',
            message: 'He is a gem. Understands the requirements and a hard worker to subsequently implement it. We used him for implementing machine to machine authentication on auth0 as well as user authentication both on a multitenancy system in Azure.',
            id: '24',
            date: 'Mar 9, 2023',
            author: 'Alex J.',
            country: 'Denmark '
        },
        {
            title: 'Senior Python developer with Flask experience',
            message: 'Kirill is a great communicator and has excellent technical skills. He\'s one of the top freelancers we\'ve worked with.',
            id: '25',
            date: 'Mar 10, 2023',
            author: 'Alexander U.',
            country: 'United States'
        },
    ],
    // 2: [
    //
    //
    // ],
    2: [
        {
            title: 'Full Stack Developer Python React need expert',
            message: 'Serhii is a great developer, excellent to work with, he completed a very difficult task. He communicated promptly, is skilled and very professional. Would definitely work with him again.',
            id: '26',
            date: 'Mar 5, 2023',
            author: 'Mykola A.',
            country: 'Germany'
        },
        {
            title: 'Full Stack Developer Python React need expert',
            message: 'Excellent freelancer, strongly recommended. Completed all tasks on schedule, quickly and accurately. Communication was good.',
            id: '27',
            date: 'Mar 5, 2023',
            author: 'Mykola A.',
            country: 'Germany'
        },
        {
            title: 'We need Python and React developer',
            message: 'Roman did a perfect job, he is really a great developer. Completed everything quickly, easy communication, reliable person. Recommended.',
            id: '28',
            date: 'Oct 14, 2022',
            author: 'Liliia L.',
            country: 'Germany'
        },
        {
            title: 'We need Python and React developer',
            message: 'Thank you Vitaliy for an excellent job. Vitaliy delivered great job in time frame agreed on. Looking forward to hire him again.',
            id: '29',
            date: 'Oct 14, 2022',
            author: 'Liliia L.',
            country: 'Germany'
        },
        {
            title: 'Python Developer with ArangoDB Experience',
            message: 'Alex knows his stuff when it comes to ArangoDB. Would hire again without hesitation',
            id: '30',
            date: 'Oct 14, 2022',
            author: 'David G.',
            country: 'United Kingdom'
        },
        {
            title: 'Strong Python developer',
            message: 'It was a pleasure to work with Alex. Greatly exceeded expectations on our complex project. Good and clear daily communications, great attention to details. Will be glad to hire again in the future. Thank you, Alex',
            id: '31',
            date: 'Sep 20, 2022',
            author: 'Mark H.',
            country: 'Germany'
        },
        {
            title: 'Searching developer for machine learning cloud platform developed in python and node.js',
            message: 'We enjoy working with Kirill very much. He is a reliable and skilled developer.',
            id: '32',
            date: 'May 10, 2022',
            author: 'Kim H.',
            country: 'Germany'
        },
        {
            title: 'Need Django developer with DevOps experience',
            message: 'Kirill is smart, fast and very cooperative. Glad to work with him. Thank you so much for your time and effort.',
            id: '33',
            date: 'Jul 25, 2022',
            author: 'Paul M.',
            country: 'Germany'
        },
        {
            title: 'Django app and AWS setup',
            message: 'I highly recommend Kirill. He is a very talented and focused developer. He did his work extremely efficiently and produced excellent work, thanks a lot. I look forward to working with him again in the near future.',
            id: '34',
            date: 'Jun 28, 2022',
            author: 'Terry G.',
            country: 'United States'
        },
        {
            title: 'Python Trading Bot( API | Zerodha | Fyers | Angel)',
            message: 'Thank you for a great job, reliable and smart developer!',
            id: '35',
            date: 'Mar 22, 2022',
            author: 'Karim S.',
            country: 'United Arab Emirates'
        },
        {
            title: 'Python backend dev (NLP/Machine learning)',
            message: 'Denis delivered the best solution, I enjoyed working with him. He has great communications and his skills are strong. I enjoyed working with Denis and will hire him again for my project in future.',
            id: '36',
            date: 'Jul 26, 2022',
            author: 'Philippe B',
            country: 'France'
        },
        {
            title: 'Python Full Stack Developer Needed',
            message: 'Sergei did a great job, according to deadlines and with a high-quality, thanks. I enjoyed working with him and will be glad to work in the future',
            id: '37',
            date: 'Apr 28, 2022',
            author: 'Martin S.',
            country: 'Germany'
        },
    ],
    3: [

        {
            title: 'Facebook Ads Library Data Scraper',
            message: 'Great job! Fast research, good ideas and high-quality completed task. Recommend to work!',
            id: '38',
            date: 'Apr 21, 2022',
            author: 'Vladyslav A.',
            country: 'Ukraine'
        },
        {
            title: 'Bitcoin BiIP39 Mnemonic/Address Mass Generator',
            message: 'He did a wonderful job thank you',
            id: '39',
            date: 'Apr 29, 2022',
            author: 'Nazma K.',
            country: 'United States'
        },
        {
            title: 'Full Stack Developer',
            message: 'Vlad delivered good work on this small project and I enjoyed working with him. His communication was top-notch, he met all deadlines, and his skills are strong. I enjoyed working with Vlad and will likely have additional jobs for him in the future, thanks for your work',
            id: '40',
            date: 'Apr 8, 2022',
            author: 'Claudio C.',
            country: 'Italy'
        },
        {
            title: 'Creating a database of contacts of sound producers',
            message: 'Denis completed the assignment on time and helped a lot in analyzing and choosing best sources for data parcing. Great work – will definitely hire again.',
            id: '41',
            date: 'Feb 22, 2022',
            author: 'Elena A',
            country: 'Ukraine'
        },
        {
            title: 'Implement the contract and adjust terms and conditions',
            message: 'The cooperation was very pleasant. He immediately understood my concerns and what needed to be done. Technically, everything was done very well and I am very satisfied. But the social aspect is also very good. He is polite and always helpful and goes above and beyond.',
            id: '42',
            date: 'Jul 27, 2023',
            author: 'Mick M',
            country: 'Switzerland'
        },
  // {
  //           title: 'Excel/Python Script Developer for Dynamic Unique Links',
  //           message: 'Solved the task perfectly and was very responsive. And helpful throughout the whole process.',
  //           id: '43',
  //           date: 'Jul 10, 2023',
  //           author: 'Erlend A',
  //           country: 'Norway'
  //       },

    ],
};
export const headerButtonsList: IHeader[] = [
    {id: 1, title: 'SERVICES', name: 'services'},
    {id: 2, title: 'TECHNOLOGIES', name: 'technologies'},
    {id: 3, title: 'TESTIMONIALS', name: 'feedback'},
    {id: 4, title: 'ABOUT US', name: 'about'},
    {id: 5, title: 'TEAM', name: 'team'},
    {id: 6, title: 'CONTACT US', name: 'contact'},
]

export const mainListOneContent: ITitleId[] = [
    {id: 1, title: 'Blockchain & DeFi'},
    {id: 2, title: 'E-commerce'},
    {id: 3, title: 'Healthcare & Beauty'},
    {id: 4, title: 'Travel & Booking'},
    {id: 5, title: 'Education & Training'},
    {id: 6, title: 'Productivity'},
    {id: 7, title: 'Marketing'},
    {id: 8, title: 'Logistics'},
]

export const technologiesData: ITechnologies[] = [
    {
        id: 1,
        title: 'Programming languages',
        payload: [
            {id: 1, description: 'HTML', color: '#4179BA', fontSize: 40,},
            {id: 2, description: 'TypeScript', color: '#4179BA', fontSize: 82},
            {id: 3, description: 'PHP', color: '#0AA9CC', fontSize: 24},
            {id: 4, description: 'Erlang', color: '#4179BA', fontSize: 24},
            {id: 5, description: 'SQL', color: '#4179BA', fontSize: 36},
            {id: 6, description: 'Go', color: '#4179BA', fontSize: 32},
            {id: 14, description: 'Nim', color: '#0AA9CC', fontSize: 24},
            {id: 7, description: 'T-SQL', color: '#4179BA', fontSize: 36},
            {id: 8, description: 'Java', color: '#0AA9CC', fontSize: 40},
            {id: 9, description: 'CSS', color: '#4179BA', fontSize: 40},
            {id: 10, description: 'Python', color: '#0AA9CC', fontSize: 48},
            {id: 11, description: 'Rust', color: '#4179BA', fontSize: 30},
            {id: 12, description: 'NodeJS', color: '#0AA9CC', fontSize: 20},
            {id: 13, description: 'JavaScript', color: '#4179BA', fontSize: 64},
        ]
    },
    {
        id: 2,
        title: 'Frameworks / Libraries',
        payload: [
            {id: 1, description: 'SQLAlchemy', color: '#0AA9CC', fontSize: 40,},
            {id: 2, description: 'Django', color: '#4179BA', fontSize: 82,},
            {id: 3, description: 'Numpy', color: '#0AA9CC', fontSize: 48,},
            {id: 4, description: 'Angular', color: '#4179BA', fontSize: 24,},
            {id: 5, description: 'ReactJS', color: '#4179BA', fontSize: 36,},
            {id: 6, description: 'NodeJS', color: '#0AA9CC', fontSize: 20,},
            {id: 7, description: 'FastAPI', color: '#0AA9CC', fontSize: 48,},
            {id: 8, description: 'Query', color: '#0AA9CC', fontSize: 32,},
            {id: 9, description: 'Rust', color: '#4179BA', fontSize: 30,},
            {id: 10, description: 'Flask', color: '#4179BA', fontSize: 82,},
            {id: 11, description: 'Pandas', color: '#4179BA', fontSize: 36,},
        ]
    },
    {
        id: 3,
        title: 'Technologies',
        payload: [
            {id: 1, description: 'Software Architecture Design', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'RIA  Research & Development', color: '#0AA9CC', fontSize: 40,},
            {id: 3, description: 'XML', color: '#4179BA', fontSize: 24,},
            {id: 4, description: 'Reports', color: '#0AA9CC', fontSize: 32,},
            {id: 5, description: 'YAML', color: '#0AA9CC', fontSize: 20,},
            {id: 6, description: 'PHP', color: '#0AA9CC', fontSize: 24,},
            {id: 7, description: 'SRS', color: '#4179BA', fontSize: 40,},
            {id: 8, description: 'Proposals', color: '#4179BA', fontSize: 32,},
            {id: 9, description: 'Design Patterns', color: '#4179BA', fontSize: 36,},
            {id: 10, description: 'UML', color: '#4179BA', fontSize: 64,},
            {id: 11, description: 'JSON', color: '#0AA9CC', fontSize: 24,},
            {id: 12, description: 'SaaS', color: '#4179BA', fontSize: 36,},
            {id: 13, description: 'Reverse Engineering', color: '#0AA9CC', fontSize: 48,},
            {id: 14, description: 'Technical Documentation', color: '#4179BA', fontSize: 30,},
            {id: 15, description: 'Architecture', color: '#0AA9CC', fontSize: 20,},
        ]
    },
    {
        id: 4,
        title: 'RDBMS',
        payload: [
            {id: 1, description: 'MySQL', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'PostgreSQL', color: '#0AA9CC', fontSize: 48,},
        ]
    },
    {
        id: 5,
        title: 'NoSQL',
        payload: [
            {id: 1, description: 'MongoDB', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'Elasticsearch', color: '#4179BA', fontSize: 30,},
            {id: 3, description: 'Redis', color: '#0AA9CC', fontSize: 48,},
        ]
    },
    {
        id: 6,
        title: 'Virtualization tools',
        payload: [
            {id: 1, description: 'Docker', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'Kubernetes', color: '#0AA9CC', fontSize: 48,},
        ]
    },
    {
        id: 7,
        title: 'Methodologies',
        payload: [
            {id: 1, description: 'XP', color: '#0AA9CC', fontSize: 36,},
            {id: 4, description: 'Feature Driven', color: '#0AA9CC', fontSize: 24,},
            {id: 2, description: 'Agile', color: '#4179BA', fontSize: 60,},
            {id: 3, description: 'Test Driven', color: '#0AA9CC', fontSize: 32,},
            {id: 5, description: 'Pair Programming', color: '#4179BA', fontSize: 30,},
            {id: 6, description: 'Scrum', color: '#0AA9CC', fontSize: 48,},
            {id: 7, description: 'Behavior Driven Development', color: '#4179BA', fontSize: 32,},
        ]
    },
    {
        id: 8,
        title: 'Operating systems',
        payload: [
            {id: 1, description: 'Microsoft Windows', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'LinuxMint', color: '#0AA9CC', fontSize: 48,},
            {id: 3, description: 'Debian', color: '#4179BA', fontSize: 30,},
            {id: 4, description: 'CentOS', color: '#0AA9CC', fontSize: 48,},
            {id: 5, description: 'Ubuntu', color: '#0AA9CC', fontSize: 32,},
            {id: 6, description: 'MacOS', color: '#4179BA', fontSize: 36,},
        ]
    },
    {
        id: 9,
        title: 'Application / Web servers',
        payload: [
            {id: 1, description: 'Apache', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'Nginx', color: '#0AA9CC', fontSize: 48,},
        ]
    },
    {
        id: 10,
        title: 'Cloud providers',
        payload: [
            {id: 1, description: 'Amazon Web Services', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'Google Cloud Provider', color: '#4179BA', fontSize: 30,},
            {id: 3, description: 'Microsoft Azure', color: '#0AA9CC', fontSize: 48,},
            {id: 4, description: 'Heroku', color: '#0AA9CC', fontSize: 32,},
        ]
    },
    {
        id: 11,
        title: 'Development tools',
        payload: [
            {id: 1, description: 'JetBrains', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'VSCode', color: '#0AA9CC', fontSize: 48,},
        ]
    },
    {
        id: 12,
        title: 'Testing tools',
        payload: [
            {id: 1, description: 'Postman', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'Selenium', color: '#0AA9CC', fontSize: 48,},
        ]
    },
    {
        id: 13,
        title: 'Hardware',
        payload: [
            {id: 1, description: 'Cisco', color: '#4179BA', fontSize: 60,},
            {id: 2, description: 'AT modem', color: '#4179BA', fontSize: 30,},
            {id: 3, description: 'Siemens', color: '#0AA9CC', fontSize: 48,},
            {id: 4, description: 'Arduino', color: '#0AA9CC', fontSize: 32,},
            {id: 5, description: 'Raspberry Pi', color: '#4179BA', fontSize: 36,},
        ]
    },
]

export const servicesList: IServices[] = [
    {
        id: 1,
        title: 'Artificial intelligence',
        description: `With our qualified AI specialists, we are specialized in applying all of the newest ways to developing AI-powered apps to fit your business goals.`,
        icon: intelligenceIcon,
    },
    {
        id: 2,
        title: 'Web development & design',
        description: `We build and make a special site from scratch or upgrade your current site with the latest technological innovations.`,
        icon: webDevIcon,
    },
    {
        id: 3,
        title: 'Software development',
        description: `We develop custom software that allows your company to succeed in a competitive market.`,
        icon: softwareDevIcon,
    },
    {
        id: 4,
        title: 'Mobile application development',
        description: 'We assist you in turning your ideas into functional apps, resolving issues, and ensuring that your mobile app has the ability to be revolutionary.',
        icon: mobileAppsIcon,
    },
    {
        id: 5,
        title: 'Blockchain development',
        description: 'We are harnessing the power of blockchain technology to benefit companies and help them reach their full potential.',
        icon: blockchainIcon,
    },
    {
        id: 6,
        title: 'Internet of things (IoT)',
        description: 'We create, incorporate, and build end-to-end IoT processes for companies and assist them in effectively managing and adopting this breakthrough technology.',
        icon: internetIcon,
    },
    {
        id: 7,
        title: 'Dashboards, CRM-systems',
        description: 'We build dashboards that give you an overview of the reports and metrics you care about most and create a system with technology for managing all company\'s relationships and interactions with customers and potential customers.',
        icon: dashboardIcon,
    },
    {
        id: 8,
        title: 'Data analysis',
        description: 'We develop a process of systematically applying statistical and logical techniques to describe and illustrate, condense and recap, and evaluate data of your company.',
        icon: dataAnalysisIcon,
    },
    {
        id: 9,
        title: 'WEB & SaaS applications',
        description: 'We build a software licensing and delivery model in which software is licensed on a subscription basis and is centrally hosted.',
        icon: webSassIcon,
    },
    {
        id: 10,
        title: 'API integrations',
        description: 'We develop a system that allows your company to automate business processes.',
        icon: apiIcon,
    },
]

export const inputsArray: IInputs[] = [
    {
        id: 1,
        label: 'Name: *',
        type: 'text',
        nameDescription: 'name',

    },
    {
        id: 2,
        label: 'Project type:',
        type: 'text',
        nameDescription: 'type',
    },
    {
        id: 3,
        label: 'Email: *',
        type: 'text',
        nameDescription: 'email',
    },
    {
        id: 4,
        label: 'Tell us about the project:',
        type: 'text',
        nameDescription: 'about',
    },
    {
        id: 5,
        label: 'Phone number: *',
        type: 'text',
        nameDescription: 'number',
    },
    {
        id: 6,
        label: 'add document',
        type: 'file',
        nameDescription: 'document',
    },
]

export const aboutUsData: IAboutUs = {
    executive: [
        {
            id: 8,
            name: 'Roman Grebinchak',
            profession: 'Full Stack Developer',
            experience: '6.5 years of experience',
            photo: RomanGrebinchak,
        },
        {
            id: 3,
            name: 'Kirill Leontiev ',
            profession: 'Full Stack Developer',
            experience: '6 years of experience',
            photo: KirillLeontyev,
        },
        {
            id: 5,
            name: 'Sergei Lemeshev',
            profession: 'Full Stack Developer',
            experience: '6 years of experience',
            photo: SergeiLemeshev,
        },
        {
            id: 4,
            name: 'Artem Logachev',
            profession: 'Full Stack Developer',
            experience: '6 years of experience',
            photo: ArtemLogachev,
        },
        {
            id: 12222134,
            name: 'Andrey Millovich',
            profession: 'Full Stack Developer',
            experience: '6 years of experience',
            photo: AndreyMillovich,
        },
        {
            id: 19,
            name: 'Hennadii Mitrov',
            profession: 'Back-end developer',
            experience: '5 years of experience',
            photo: HennadiyMitrov,
        },

        {
            id: 2,
            name: 'Vladyslav Golovanov',
            profession: 'Full Stack Developer',
            experience: '5 years of experience',
            photo: VladGolovanov,
        },



        {
            id: 22,
            name: 'Valentyn Slivko',
            profession: 'Full Stack Developer, DevOps Engineer',
            experience: '5 years of experience',
            photo: ValentynSlivko,
        },
        {
            id: 10,
            name: 'Vitaliy Sekach',
            profession: 'Lead Back-End Developer',
            experience: '5 years of experience',
            photo: VitaliySekach,
        },
        {
            id: 12,
            name: 'Roman Poplietukhin',
            profession: 'Front End Developer',
            experience: '5 years of experience',
            photo: RomanPoplietukhin,
        },

        {
            id: 233499,
            name: 'Vladymyr Ivanus',
            profession: 'Full Stack Developer',
            experience: '5 years of experience',
            photo: VladymyrIvanus,
        },
        {
            id: 14,
            name: 'Eugene Kudrin',
            profession: 'Full Stack Developer',
            experience: '4,5 years of experience',
            photo: EugeneKudrin,
        },
        {
            id: 1134,
            name: 'Andrey Dmitriyev',
            profession: 'Back-end developer',
            experience: '4,5 years of experience',
            photo: AndreyDmitriyev,
        },
        {
            id: 20,
            name: 'Dmitry Dzyubenko',
            profession: 'Back-end developer',
            experience: '4,5 years of experience',
            photo: DmitryDzyubenko,
        },
        {
            id: 21,
            name: 'Sergei Soldatov ',
            profession: 'Full Stack Developer',
            experience: '4 years of experience',
            photo: SoldatovSerhii,
        },
        {
            id: 6,
            name: 'Eduard Terletskiy',
            profession: 'Full Stack Developer',
            experience: '4 years of experience',
            photo: EduardTerletskiy,
        },
        {
            id: 21,
            name: 'Pavel Maslo',
            profession: 'Back-end developer',
            experience: '4 years of experience',
            photo: PavelMaslo,
        },

        {
            id: 928,
            name: 'Valentin Volynskiy',
            profession: 'Full Stack Developer',
            experience: '4 years of experience',
            photo: ValentinVolynskiy,
        },
        // {
        //     id: 928,
        //     name: 'Yura Soboliev',
        //     profession: 'Back-end developer',
        //     experience: '4 years of experience',
        //     photo: '',
        // },

        {
            id: 1134,
            name: 'Vlad Svyrydenko',
            profession: 'Full stack developer',
            experience: '4 years of experience',
            photo: VladSvyrydenko,
        },
        {
            id: 13556541,
            name: 'Yaroslav Borysko',
            profession: 'Full stack developer',
            experience: '4 years of experience',
            photo: YaroslavBorysko,
        },

        {
            id: 2299,
            name: 'Artur Nesterenko',
            profession: 'Front-end developer',
            experience: '4 years of experience',
            photo: ArturNesterenko,
        },
        {
            id: 113342344,
            name: 'Marko Khodan',
            profession: 'Back-end developer',
            experience: '3,5 years of experience',
            photo: MarkoKhodan,
        },
        {
            id: 11334332344,
            name: 'Oleksii Skursky',
            profession: 'Full stack developer',
            experience: '3,5 years of experience',
            photo: OleksiiSkursky,
        },
        {
            id: 11,
            name: 'Pavel Kononenko',
            profession: 'AI/ML Engineer, Full Stack Developer',
            experience: '3,5 years of experience',
            photo: PavelKononenko,
        },
        {
            id: 1441,
            name: 'Sergei Honcharov',
            profession: 'Back-end developer',
            experience: '3,5 years of experience',
            photo: SergeiHoncharov,
        },
        {
            id: 20978,
            name: 'Michael Potemkin',
            profession: 'AI/ML Engineer, Back-End Developer',
            experience: '3,5 years of experience',
            photo: MichaelPotemkin,
        },
        {
            id: 292339,
            name: 'Artem Hirzhev',
            profession: 'Front-end developer',
            experience: '3 years of experience',
            photo: ArtemHirzhev,
        },
        {
            id: 1433341,
            name: 'Vladyslav Rybnyk',
            profession: 'DevOps engineer',
            experience: '3 years of experience',
            photo: VladyslavRybnyk,
        },
        {
            id: 1323341,
            name: 'Mark Zaianchkovskyi',
            profession: 'Back-end developer',
            experience: '3 years of experience',
            photo: MarkZaianchkovskyi,
        },

        {
            id: 2098,
            name: 'Bogdan Pizhuk',
            profession: 'Back-end developer',
            experience: '3 years of experience',
            photo: BogdanPizhuk,
        },
        {
            id: 299945459,
            name: 'Tanya Hrytsiuk',
            profession: ' UI/UX Designer',
            experience: '3 years of experience',
            photo: TanyaHrytsiuk,
        },


        {
            id: 2098,
            name: 'Bogdan Kostenko',
            profession: 'Back-end developer',
            experience: '3 years of experience',
            photo: BogdanKostenko,
        },
        {
            id: 20983,
            name: 'Oxana Vorobel',
            profession: 'Back-end developer',
            experience: '3 years of experience',
            photo: OxanaVorobel,
        },
        {
            id: 23499,
            name: 'Ivan Shulhan',
            profession: 'Full Stack Developer',
            experience: '3 years of experience',
            photo: IvanShulhan,
        },

        // {
        //     id: 2334989,
        //     name: 'Illia Yegorov',
        //     profession: 'Full Stack Developer',
        //     experience: '3 years of experience',
        //     photo: '',
        // },
        {
            id: 29,
            name: 'Diana Davlatova',
            profession: 'Back-end developer',
            experience: '3 years of experience',
            photo: DianaDavlatova,
        },
        {
            id: 233399,
            name: 'Dmitriy Vikhtiuk',
            profession: 'Back-End Developer',
            experience: '3 years of experience',
            photo: DmytroVikhtiuk,
        },
        {
            id: 29999,
            name: 'Yaroslav Kovaliv',
            profession: 'Full stack developer',
            experience: '2 years of experience',
            photo: YaroslavKovaliv,
        },


        //
        // {
        //     id: 233399,
        //     name: 'Eduard Mazurenko',
        //     profession: 'Back-End Developer',
        //     experience: '2 years of experience',
        //     photo: '',
        // },


    ],
    business: [
        {
            id: 15675,
            name: 'Michael Sizonenko',
            profession: 'CEO/CTO',
            experience: '14 years of experience',
            photo: MichaelSizonenko,
        },
        {
            id: 15,
            name: 'Irina Iskenderova',
            profession: 'CEO',
            experience: '10 years of experience',
            photo: IrinaIskenderova,
        },
        {
            id: 15,
            name: 'Igor Savula',
            profession: 'CMO',
            experience: '12 years of experience',
            photo: IgorSavula,
        },
        {
            id: 17,
            name: 'Andrey Nikolaiev',
            profession: 'Business Developer',
            experience: '7 years of experience',
            photo: AndriiNikolaiev,
        },
        {
            id: 9,
            name: 'Kate Rozhok',
            profession: 'Sales Manager',
            experience: '5 years of experience',
            photo: KateRozhok,
        },
        {
            id: 9,
            name: 'Maryna Tomchyshena',
            profession: 'BDR',
            experience: '5 years of experience',
            photo: MarynaTomchyshena,
        },
        {
            id: 18,
            name: 'Daria Korotkaya',
            profession: 'HR - manager',
            experience: '4 years of experience',
            photo: DariaKorotkaya,
        },
    ]
}

