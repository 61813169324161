import { motion} from "framer-motion";
import {FC, useEffect, useState} from "react";
import s from "./Item.module.scss";
import starIcon from "../../../assets/graphics/star.svg";
import {IFeedbackItem} from "../../../data/dataTsConfiguration";
import {Direction} from "../FeedBack";
import { useMediaQuery} from "@mui/material";
import {testimonialsItemRate} from "../../../data/componentsData";

interface Props extends IFeedbackItem {
    idx: number;
    isOpen: string;
    setIsOpen: (arg: string) => void;
    direction: Direction | null;
}
enum Overflow {
    initial = 'initial',
    hidden = 'hidden'
}

export const Item: FC<Props> = (props) => {
    const {
        message,
        idx,
        id,
        date,
        author,
        title,
        country,
        direction
    } = props;

    const isMobile = useMediaQuery("(max-width:768px)");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isMobile && isOpen) {
            document.body.style.overflow = Overflow.hidden
        } else {
            document.body.style.overflow = Overflow.initial
        }
    }, [isMobile, isOpen]);

    const handleOpen = () => setIsOpen(!isOpen)

    return (
        <motion.div
            initial={{
                opacity: 0,
                [isMobile ? 'x' : 'y']: direction === "right" ? 100 : -100
            }}
            animate={{
                [isMobile ? 'x' : 'y']: 0,
                opacity: 1,
                transition: {
                    duration: 0.5,
                }
            }}
            key={idx}
            transition={{duration: 0.2}}
            onClick={handleOpen}
            style={{
                position: isMobile
                    ? isOpen ? 'fixed' : 'static'
                    : isOpen ? 'absolute' : 'static',
                justifyContent: isOpen ? 'flex-start' : 'space-evenly',
            }}
            className={s.item}
            data-isOpen={isOpen}
            layoutId={id}
        >
            <div className={s.item_header}>
                <div>
                    <img className={s.star_icon} src={starIcon} alt="raiting icon"/>
                    <img className={s.star_icon} src={starIcon} alt="raiting icon"/>
                    <img className={s.star_icon} src={starIcon} alt="raiting icon"/>
                    <img className={s.star_icon} src={starIcon} alt="raiting icon"/>
                    <img className={s.star_icon} src={starIcon} alt="raiting icon"/>
                </div>
                <p className={s.date}>{date}</p>
            </div>

            <div className={s.main_content}>
                <p
                    style={{display: !isOpen ? 'none' : 'block'}}
                    className={s.title}
                >
                    {title}
                </p>
                <p className={s.author}>{author}</p>
                <p
                    className={s.message}
                    style={{
                        height: isOpen ? 'fit-content' : '4em',
                        display: isOpen ? 'block' : '-webkit-box',
                        marginBottom: isOpen ? '20px' : 0,
                        fontSize: isOpen
                            ? !isMobile ? '1.2em' : '1em'
                            : '1em'
                    }}
                >
                    {message}
                </p>
            </div>
            <div style={{width: isMobile ? '100%' : '80%', margin: 'auto'}}>
                {isOpen
                    ? (
                        <div className={s.additional_topic}>
                            {testimonialsItemRate.map((el, index) => (
                                <motion.div
                                    key={el.id}
                                    initial={{opacity: 0, [isMobile ? 'x' : 'y']: -20}}
                                    animate={{opacity: 1, [isMobile ? 'x' : 'y']: 0}}
                                    transition={{delay: 0.5 * index}}
                                >
                                    <p>{el.title}</p>
                                    <p>{el.skills}</p>
                                </motion.div>
                            ))}
                        </div>
                    )
                    : null
                }
            </div>

        </motion.div>

    );
};

