import axios from "axios";

const instanceAPI = axios.create({
  baseURL: 'https://ujvzaphxu2.execute-api.eu-central-1.amazonaws.com',
})

export const testAPI = {
  sendMail(payload: any) {

    let formData = new FormData();
    formData.append('name', payload.name)
    formData.append('email', payload.email)
    formData.append("image", payload.file);

    return instanceAPI.post("/email", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  },
}