import {ChangeEvent, FormEvent, useEffect, useState} from "react";
import s from './NewRequest.module.scss'
import sMain from './../../common/styles/styles.module.scss'
import clip from '../../assets/graphics/clip.svg'
import {useMediaPredicate} from "react-media-hook";
import Snackbar from '@mui/material/Snackbar';
import {testAPI} from "../../api/api";
import {Alert} from "@mui/material";

export const NewRequest = () => {
    const is1200 = useMediaPredicate('(max-width: 1200px)')
    const is500 = useMediaPredicate('(max-width: 500px)')
    const [mainFocus, setMainFocus] = useState<boolean>(false)
    const [nameFocus, setNameFocus] = useState<boolean>(false)
    const [emailFocus, setEmailFocus] = useState<boolean>(false)

    const [nameValue, setNameValue] = useState<string>('')
    const [emailValue, setEmailValue] = useState<string>('')
    const [file, setFile] = useState(null)
    const [error, setError] = useState<boolean>(false)
    const [fileAdded, setFileAdded] = useState<string>('')
    const [showModal, setShowModal] = useState<boolean>(false)

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {

        e.preventDefault()

        setError(false)
        const data = {
            name: nameValue,
            email: emailValue,
            file: file
        }

        await testAPI.sendMail(data)
            .then((resp) => {
                if(resp.status === 200) {
                    setShowModal(true)
                    setFile(null)
                    setEmailValue('')
                    setFileAdded('')
                    setNameValue('')
                    setMainFocus(false)
                    setNameFocus(false)
                    setEmailFocus(false)
                }
            })
            .catch((error) => {
                error && setError(true)
                setShowModal(true)
            })

    }

    const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        setFile(e.target.files[0])
        const revString = e.target.value.split('').reverse()
        const idx = revString.indexOf('\\')
        revString.splice(idx)
        setFileAdded(revString.reverse().join(''))
    }

    const handleChangeFocus = () => {
        setTimeout(() => {
            !mainFocus && setMainFocus(false)

        },500)
    }

    useEffect(() => {
        if (nameValue.length || emailValue.length || fileAdded.length || nameFocus || emailFocus) {
            setMainFocus(true)
        } else {
            setMainFocus(false)
        }
    }, [nameValue, emailValue, fileAdded, nameFocus, emailFocus])

    return (
        <div className={s.requestContainer} >
            <Snackbar
                open={showModal}
                autoHideDuration={3000}
                onClose={() => setShowModal(false)}
                sx={{position: 'absolute'}}
            >
                <Alert
                       sx={{
                           backgroundColor: error ? '#FF7373' : '#55e1af',
                           margin: '1vh',
                           color: 'white',
                           width: `${is500 && '50%'}`,
                       }}>
                    {error ? 'Something went wrong' : 'Success'}
                </Alert>
            </Snackbar>

            <h2 className={`${sMain.title} ${s.title}`}> REQUEST A QUOTE </h2>

            {
                !is1200 && mainFocus && (
                    <div className={s.objectSimulate}>
                        <p>{'{'}</p>
                        {
                            (nameFocus || nameValue.length > 0) &&
                                <p className={`${s.str}  ${ nameFocus ? s.nameField : ''}`}>
                                    "name": "{nameValue}",
                                </p>
                        }
                        {
                            (emailFocus || emailValue.length > 0) &&
                                <p className={`${s.str}  ${s.emailField}`}>
                                    "email": "{emailValue}"
                                </p>
                        }
                        {
                            mainFocus &&
                                <p className={`${s.str}  ${s.fileField}`}>
                                    "document_attached": {fileAdded.length ? 'true' : 'false'}
                                </p>
                        }
                        <p>{'}'}</p>
                    </div>
                )

            }

            <form className={`${s.form} ${sMain.container} ${mainFocus ? s.activeForm : '' }`}  onSubmit={handleSubmit}>
                <div className={s.inputContainer}>
                    <input
                        className={s.inputField}
                        value={nameValue}
                        onFocus={() => setNameFocus(true)}
                        onBlur={handleChangeFocus}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value)}
                        type="text"
                        placeholder='Name'
                        required
                    />
                </div>
                <div className={s.inputContainer}>

                    <input
                        className={s.inputField}
                        value={emailValue}
                        onFocus={() => setEmailFocus(true)}
                        onBlur={handleChangeFocus}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailValue(e.target.value)}
                        placeholder='Email'
                        type="email"
                        required
                    />
                </div>
                <div className={`${s.inputContainer} ${s.fileInput}`}>
                    <label>
                        <img src={clip} alt='clip'/>
                        <span> {fileAdded || "Add document"}</span>
                        <input type="file" onChange={handleFile}/>
                    </label>
                </div>
                <button type='submit' className={s.requestButton}> Send request</button>
            </form>
        </div>
    )
}