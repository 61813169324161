import {Header} from './components/C001-Header/Header';
import {Main} from "./components/C002-Main/Main";
import {Services} from './components/С003-Services/Services';
import {Technologies} from "./components/C006-Technologies/Technologies/Technologies";
import {AboutUsMain} from "./components/C008-AboutUsMain/AboutUsMain";
import {Footer} from "./components/C009-Footer";
import {useState} from "react";
import { NewRequest } from "./components/C006-NewRequest/NewRequest";
import './App.css'
import {FeedBack} from "./components/C-01-FeedBack/FeedBack";

function App() {
    const [stickyHeader, setStickyHeader] = useState<boolean>(false)
    const [isOpenBurger, setIsOpenBurger] = useState<boolean>(false)

    return (
        <div>
            <Header
                isOpenBurger={isOpenBurger}
                setIsOpenBurger={setIsOpenBurger}
                stickyHeader={stickyHeader}
                setStickyHeader={setStickyHeader}
            />
            <Main
                isOpenBurger={isOpenBurger}
                setIsOpenBurger={setIsOpenBurger}
                stickyHeader={stickyHeader}
                setStickyHeader={setStickyHeader}
            />
            <Services stickyHeader={stickyHeader}/>
            <NewRequest/>
            {/*<Request/>*/}
            <Technologies/>
            {/*<PortfolioMain />*/}
            <FeedBack/>
            <AboutUsMain />
            <Footer/>
        </div>
    );
}

export default App;
