import {useState, useEffect} from 'react'

const useBodyScrollLock = () => {
    const bodyStyle = document.body.style

    const [isBlocked, setIsBlocked] = useState(bodyStyle.overflow === 'hidden')

    useEffect(() => {
        bodyStyle.overflow = isBlocked ? 'hidden' : 'auto'
    }, [isBlocked, bodyStyle])

    const toggle = (value: boolean)=> {
        setIsBlocked(value)
    }

    return toggle
}

export default useBodyScrollLock