import s from './Main.module.scss';
import meduzOne from '../../assets/graphics/meduz 1.svg';
import {mainListOneContent} from "../../data/componentsData";
import {IHeaderMainProps} from "../../data/dataTsConfiguration";
import {useEffect} from "react";
import JellyFishMain from "../C011-Common/JellyFishMain/JellyFishMain";
import {useMediaPredicate} from "react-media-hook";
import {motion} from "framer-motion";

export const Main: React.FC<IHeaderMainProps> = (props) => {

    const {isOpenBurger, setIsOpenBurger, setStickyHeader} = props
    const is1200 = useMediaPredicate('(max-width: 1200px)')
    const stickyHeader = is1200 ? 400 : 600

    const handleStickyHeader = () => window.scrollY > stickyHeader ? setStickyHeader(true) : setStickyHeader(false)

    useEffect(() => {
        window.addEventListener("scroll", handleStickyHeader)
        return () => {
            window.removeEventListener('scroll', handleStickyHeader)
        }
    }, [window])

    const firstPartOfTitle = 'Excellent Web and Mobile Development for your business'
    const secondPartOfTitle = 'Describe your ideas for us and we will bring them to life!'
    const textTwo = 'We are a team of developers focused on delivering high-quality solutions to enhance business. Our company provides outsourced and out staff services to reliable constant customers and startups. Our team prefers to use unique solutions for the best expectations of the product’s implementation'
    const textThree = 'Domains we work on'

    const textListOneContentLength = mainListOneContent.length

    const textListOne = mainListOneContent.map((text, index) => (
            <div
                className={`${s.textListOne} ${index < textListOneContentLength && s.textListFirst}`}
                key={text.id}>

                <span> {text.title} </span>

            </div>
        )
    )

    return (
        <div className={`${s.main} ${isOpenBurger ? s.openedBurger : s.closeBurger}`}
             onClick={() => setIsOpenBurger(false)}>

            <motion.img
                src={meduzOne}
                className={s.meduzOne}
                alt='img'
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{delay: 1.5}}
                whileTap={{
                    rotate: 360
                }}
            />

            <div className={s.mainContainer}>
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 1.5}}
                >
                    <JellyFishMain/>
                </motion.div>

                <div className={s.mainSubContainer}>
                    <motion.div
                        className={s.textOne}
                        initial={{x: -100, opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                    >
                        {firstPartOfTitle}
                    </motion.div>
                    <motion.div
                        className={s.secondPartOfTitle}
                        initial={{x: -100, opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                        transition={{delay: .5}}
                    >
                        {secondPartOfTitle}
                    </motion.div>

                    <motion.div
                        className={s.textTwo}
                        initial={{x: -100, opacity: 0}}
                        animate={{x: 0, opacity: 1}}
                        transition={{delay: 1}}
                    >
                        {textTwo}
                    </motion.div>
                </div>

                <motion.div
                    className={`${s.mainSubContainer} ${s.listContainer}`}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 1.5}}
                >
                    <div className={s.textThree}>{textThree}</div>
                    <div className={s.containerTextList}>{textListOne}</div>
                </motion.div>
            </div>
        </div>
    )

}


