import teamStyles from './Team.module.scss'
import sMain from "../../../common/styles/styles.module.scss";
import { ExecutiveDepertment } from "../ExecutiveDepertment";
import { BusinessDepartment } from "../BusinessDepartment";
import {useMediaPredicate} from "react-media-hook";

const componentTitle = 'TEAM'

export const Team = () => {

    const isMobile = useMediaPredicate("(max-width: 1200px)")

    return (
        <div className={ `${teamStyles.mainContainer } `} >
            <h2 className={`${sMain.title} ${teamStyles.titlePosition}`} id='team'>{componentTitle}</h2>
            <ExecutiveDepertment/>
            <BusinessDepartment/>
        </div>
    )
}
