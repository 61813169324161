import carouselStyles from './Carousel.module.scss'
import {TeamPerson} from "../TeamPerson";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Mousewheel} from 'swiper';

type propTypes = {
  data: {
    id: number,
    name: string,
    profession: string,
    photo: string,
    experience: string
  }[]
  autoplay: boolean,
  delay: number
}

export const Carousel: React.FC<propTypes> = (props) => {

  const {data, autoplay, delay} = props

  const cardsList = data.map(person => (
    <SwiperSlide key={person.id} className={carouselStyles.slide}>
      <TeamPerson person={person}/>
    </SwiperSlide>
  ))

  const swiperWithAutoplay = () => {
    return (
      <Swiper
        slidesPerView={'auto'}
        className={carouselStyles.swiperWrapper}
        loop={autoplay}
        mousewheel={true}
        loopFillGroupWithBlank={true}
        autoplay={{
          delay: delay,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        modules={[Autoplay, Mousewheel]}
      >
        {cardsList}
      </Swiper>
    )
  }

  const swiperWithoutAutoplay = () => {
    return (
      <Swiper
        slidesPerView={'auto'}
        className={carouselStyles.swiperWrapper}
      >
        {cardsList}
      </Swiper>
    )
  }

  return (
    <div className={carouselStyles.carouselContainer}>
      {autoplay ? swiperWithAutoplay() : swiperWithoutAutoplay()}
    </div>
  );
}