import s from "./FeedBackComponent.module.scss";
import {useState} from "react";
import {motion} from "framer-motion";
import {Item} from "../Item";
import {feedbackItems} from "../../../data/componentsData";
import {Gallery} from "../Gallery";
import {useMediaQuery} from "@mui/material";

export type Direction = "right" | "left"

export const FeedBack = () => {
    const isMobile = useMediaQuery("(max-width:768px)");
    const [isOpen, setIsOpen] = useState<string>("");
    const [idx, setIdx] = useState<number>(0);
    const [direction, setDirection] = useState<Direction | null>(null);
    const mockARrr = new Array(Object.values(feedbackItems));

    return (
        <section className={s.container}>
            <div className={s.title_wrapper}>
                <h2 className={s.section_title} id='feedback'>Testimonials</h2>
                <p className={s.sub_title}>what customers write about us</p>
            </div>
            <div className={s.content}>
                <motion.div
                    key={idx}
                    className={s.cards}
                    style={{overflow: isMobile ? 'auto' : 'initial'}}
                    layout
                    transition={{duration: 1}}
                >
                    <Gallery
                        setDirection={setDirection}
                        sliderLength={mockARrr[0].length}
                        setIdx={setIdx}
                        idx={idx}
                    >
                        <>
                            {feedbackItems[idx]?.map(el => (
                                <Item
                                    setIsOpen={setIsOpen}
                                    isOpen={isOpen}
                                    direction={direction}
                                    idx={idx}
                                    {...el}
                                />
                            ))}
                        </>
                    </Gallery>
                </motion.div>
            </div>

        </section>
    );
};

