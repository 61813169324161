import React, {useState} from "react";
import sMain from "../../../common/styles/styles.module.scss";
import technologiesStyles from './Technologies.module.scss'
import {technologiesData} from "../../../data/componentsData";
import meduzOne from '../../../assets/graphics/meduz 1.svg'
import {useMediaPredicate} from "react-media-hook";
import {TechnologyContainer} from "../TechnologyContainer/TechnologyContainer";
import {scrollHelper} from "../../../utils/scrollHelper";
import s from "../../С003-Services/Services.module.scss";

const technologiesTitle = 'TECHNOLOGIES'

export const Technologies = () => {

  const isMobile = useMediaPredicate("(max-width: 1200px)")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showId, setShowId] = useState<number>(0)

  const changeShowState = (value: number) => setShowId(value)


  return (
    <div className={`${technologiesStyles.forBackground}`} >
      <img src={meduzOne} className={technologiesStyles.meduzOne} alt="poster"/>

      <section className={technologiesStyles.mainContainer}>

        <h2 className={`${sMain.title} ${s.titlePosition}`} id='technologies'>{technologiesTitle}</h2>

        <div className={`${technologiesStyles.technologiesContainer} ${isOpen ? technologiesStyles.openContainer : technologiesStyles.closeContainer}`}>
            {!isMobile || isOpen ? (
                technologiesData.map(el => (
                    <TechnologyContainer key={el.id} {...el} showId={showId} changeShowState={changeShowState}/>
                ))
            ) : (
                technologiesData.slice(0, 3).map(el => (
                    <TechnologyContainer key={el.id} {...el} showId={showId} changeShowState={changeShowState}/>
                ))
            )}
        </div>

        <div className={`${technologiesStyles.loadMoreBtnContainer} ${isOpen ? technologiesStyles.hideButton : ''}`}>
          <button className={sMain.contactWithUsBtn} onClick={() => {
              isOpen && scrollHelper('technologies')
              setIsOpen(!isOpen)
          }}>{ isOpen ? 'Load less' :'Load more'}</button>
        </div>
      </section>
      <img src={meduzOne} className={technologiesStyles.meduzOneRight} alt="poster"/>
    </div>
  )
}