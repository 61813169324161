import { scroller } from "react-scroll";

export const scrollHelper = (item: string, offset: number = -80) => {
    return (
        scroller.scrollTo(item,{
            duration: 300,
            delay: 0,
            offset,
        })
    )
}