import React from "react"
import s from "./JellyFishMain.module.scss";
import bigJellyfishHead from "../../../assets/graphics/footer/footer-meduz-head.svg";
import bigJellyfishTail from "../../../assets/graphics/footer/footer-meduz-tail.svg";
import pictOne from "../../../assets/graphics/pict 1.svg";
import pictTwo from "../../../assets/graphics/pict 2.svg";
import {motion} from "framer-motion";


function JellyFishMain() {
    return (
        <motion.div className={s.bigJellyfishBody}>
            <img src={bigJellyfishHead} alt="img" className={s.bigJellyfishHead}/>
            <img src={bigJellyfishTail} alt="img" className={s.bigJellyfishTail}/>
            <img src={pictOne} alt='img' className={s.pictOne}/>
            <img src={pictTwo} alt='img' className={s.pictTwo}/>
        </motion.div>
    )
}

export default JellyFishMain
